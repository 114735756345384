import React from 'react';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CustomToolTip from '../Common/CustomToolTip';
import Buttonbar from '../Common/Buttonbar';

export default function MuiTextField({
  id,
  type,
  name,
  label,
  size,
  placeholder = '',
  maxLength,
  outerStyle = {},
  defaultValue,
  autoComplete,
  onChange,
  onKeyPress,
  icon,
  iconClass,
  hovertext,
  buttonbar,
  handleButton,
  readOnly = false,
}) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(show => !show);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  let formatted_size = '9ch';
  if (typeof size == "number" || (typeof size === "string" && size.match(/\d$/))) {
    formatted_size = size < 9 ? "9ch" : `${size}ch`; 
  } else {
    formatted_size = size;
  }

  return (
    <Box
      sx={{
        width: "auto",
        '& *': {
          fontSize: '14px',
        },
        ...outerStyle,
      }}
    >
      <abbr title={hovertext}>
        <FormLabel sx={{ fontWeight: 'bold', color: 'black', display: 'block', position: 'static' }}>{label}
          {icon && <CustomToolTip sx={{ marginLeft: '5px' }} icon={icon} iconClass={iconClass} /> }
          {buttonbar && ( <Buttonbar buttonbar={buttonbar} handleButton={handleButton} />)}
        </FormLabel>
        <TextField
          id={id}
          type={type === 'password' && !showPassword ? 'password' : 'text'}
          name={name}
          placeholder={placeholder}
          maxLength={maxLength}
          variant="outlined"
          size="small"
          sx={{
            width: formatted_size,
          }}
          defaultValue={defaultValue}
          autoComplete={autoComplete ? 'on' : 'off'}
          onChange={onChange}
          onKeyPress={onKeyPress}
          InputProps={{
            readOnly,
            endAdornment:
              type === 'password' ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
        />
      </abbr>
    </Box>
  );
}
