import React from 'react';
import axios from 'axios';
//import { Helmet } from 'react-helmet';
import { Redirect, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../actions/GlobalActions';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Form from './Common/Form';
import concatURL from '../helpers/concatURL';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      msg: null,
      changePasswordMsg: null,
      username: '',
      password: '',
      remember: false,
      screenID: 0, //0 -> normal login, 1 -> send pw reset, 2 -> change pw
      resetUsername: '',
      changePassword1: '',
      changePassword2: '',
      redirectUrl: '',
      loginForm: null,
      query_params: null,
      localStorageEmail: '',
    };
  }

  componentDidMount() {
    this.fetchLoginForm();
  }

  fetchLoginForm = async () => {
    const searchParams = new URLSearchParams(window.location.search);
    const build_version = process.env.REACT_APP_LAST_COMMIT;

    let params = {
      response_type: searchParams.get('response_type'),
      client_id: searchParams.get('client_id'),
      redirect_uri: searchParams.get('redirect_uri'),
      state: searchParams.get('state'),
      user_id: searchParams.get('user_id'),
      tid: searchParams.get('tid'),
      client_name: searchParams.get('client_name'),
      token: searchParams.get('token'),
      build_version,
    };

    const keys = Object.keys(params);

    let query_params = window.location.search;

    if (query_params === '') query_params += '?';

    keys.forEach(key => {
      query_params += `${key}=${params[key]}&`;
    });

    query_params = query_params.replace(/&$/, '');

    const uid_param = params.user_id ? `?uid=${params.user_id}` : '';
    const url = concatURL('/auth/login' + uid_param);

    if (params.token) {
      // Attempt auto login
      this.props.actions.login({ ...params, username: params.user_id }, '').then(function (response) {
        if (response.success) {
          if (response.force_pw_change === true) {
            this.setState({
              screenID: 2,
              loading: false,
            });
          } else if (response.user_type === 'timeclock') {
            this.props.history.push('timeclock');
          } else {
            this.props.history.push('dashboard');
          }
          return;
        } else {
          this.setState({
            msg: 'Invalid credentials',
            loading: false,
          });
        }
      });
    }

    return axios
      .request({
        url: url,
        headers: { 'Content-Type': 'application/json' },
        method: 'GET',
        params: {ui_version: 5.1},
      })
      .then(response => {
        this.props.history.push('/login');
        if (response.data) {
          this.setState({
            loginForm: response.data,
            query_params: query_params,
            localStorageEmail: params.user_id ? params.user_id  : localStorage.getItem('nd-email') || '',
          });
        }
      })
      .catch(err => {
        console.log('Fetching login form failed:');
        console.log(err);
        throw err;
      });
  };

  handleLoginButton = params => {
    this.props.actions
      .login(params, this.state.query_params)
      .then(response => {
        if (response.success) {
          if (params.remember && params.username) {
            localStorage.setItem('nd-email', params.username);
          } else {
            localStorage.removeItem('nd-email');
          }
          if (response.force_pw_change === true) {
            this.setState({
              screenID: 2,
              loading: false,
            });
          } else if (response.redirect_to) {
              window.location.href = response.redirect_to;
          } else if (response.user_type === 'timeclock') {
            this.props.history.push('timeclock');
          } else {
            this.props.history.push('/user/dashboard');
          }
        } else {
          this.setState({
            msg: 'Invalid credentials',
            loading: false,
          });
        }
      })
      .catch(err => {
        if (err.response && err.response.data && err.response.data.errmsg) {
          this.setState({
            msg: err.response.data.errmsg,
            loading: false,
          });
        } else {
          //In case there's no err.response.data.errmsg
          this.setState({
            msg: err.message,
            loading: false,
          });
        }
      });
  };

  handleResetButton = e => {
    e.preventDefault();
    this.props.actions
      .submitPasswordReset({
        email: this.state.resetUsername,
      })
      .then(response => {
        if (response.success) {
          this.setState({
            msg: 'Password reset email sent. Check your email and click the link to reset your password.',
            screenID: 0,
          });
        }
      });
  };

  handleChangePassword = e => {
    e.preventDefault();
    this.props.actions
      .submitChangePassword({
        old_password: this.state.password,
        new_password: this.state.changePassword1,
        confirm_password: this.state.changePassword2,
      })
      .then(response => {
        if (response.success === 1) {
          this.props.history.push('/user/dashboard');
        } else {
          this.setState({
            changePasswordMsg: response.msg,
          });
        }
      });
  };

  showScreenByID = id => {
    this.setState({
      screenID: id,
    });
  };

  handleInputChange = event => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    let s = this.state;
    s[name] = value;

    this.setState({
      s,
    });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      msg: null,
    });
  };


  render() {
    const login_class = process.env.REACT_APP_THEME == "epr" ? 'login' : 'login-netduty';
    if (this.props.isLoggedIn) {
      return <Redirect to="/user/dashboard" />;
    }

    if (this.state.loginForm) {
      // the <Helmet> tag will allow the header title, and I think, the favicon, to be changed from the API
      // Use this code inside the login div below
      //<Helmet>
      //  <title>{ this.state.title }</title>
      //</Helmet>
      return (
        <div className={login_class}>
          <Snackbar open={Boolean(this.state.msg)} autoHideDuration={6000} onClose={this.handleClose}>
            <MuiAlert onClose={this.handleClose} elevation={6} variant="filled" severity="warning" className="ndAlert">
              {this.state.msg}
            </MuiAlert>
          </Snackbar>
          {this.state && this.state.screenID === 0 && (
            <Form
              standAlone={true}
              localStorageEmail={this.state.localStorageEmail}
              formData={this.state.loginForm.config}
              handleLoginButton={this.handleLoginButton}
              extraStyle={{
                minWidth: '360px',
                textAlign: 'center',
              }}
            />
          )}
        </div>
      );
    } else {
      return <div className="login" />;
    }
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.APIReducer.auth.loggedIn,
    polledNotifications: state.APIReducer.config.polledNotifications,
    redirectUrl: state.redirectUrl,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
